import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { db, storage } from '../../firebase';
import { child, equalTo, get, orderByChild, query, ref } from 'firebase/database';
import { ref as sRef, getDownloadURL } from 'firebase/storage'
import { CSVLink } from 'react-csv';
import Pagination from '../Paginations';
import axios from 'axios';

const Reports = () => {

    const uid = localStorage.getItem('uid')
    // const [releases, setReleases] = useState([])
    const [reports, setReports] = useState([])
    const [filteredReports, setFilteredReports] = useState([])
    const [yearFilter, setYearFilter] = useState([])
    const [year, setYear] = useState('')

    const navigate = useNavigate()

    useEffect(() => {

        // downloadJSONFile()

        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            console.log(snapshot.val().reportsOverview)
            setReports(snapshot.val().reportsOverview || [])
            setFilteredReports(snapshot.val().reportsOverview || [])
            getUniqueYears(snapshot.val().reportsOverview || [])
        })

        // const reportsRef = ref(db, 'uploaded_reports');

        // const userQuery = query(reportsRef, orderByChild('User ID'), equalTo(uid));
        // get(userQuery)
        // .then(snapshot => {
        //     if (snapshot.exists()) {
                // setReleases(snapshot.val().releases)
                // sortReports(snapshot.val());
                // get(ref(db, 'reports'))
                // .then(snapshot => {
                //     const reportsData = snapshot.val() || {};
                //     const reportsArray = Object.entries(reportsData).map(([key, item]) => ({...item}))
                    
                //     reportsArray.forEach(item => {
                //         fetch(item.file)
                //         .then(response => {
                //             if (!response.ok) {
                //                 throw new Error('Network response was not ok');
                //             }
                //             return response.text(); // Get the text from the response
                //         })
                //         .then(csvData => {
                //             // Process the CSV data
                //             const rows = csvData.split('\n'); // Split CSV data into rows
                //             const header = rows[0].split(','); // Extract header row
                //             const data = rows.slice(1).map(row => row.split(',')); // Extract data rows
        
                //             // Convert data to array of objects
                //             const dataArray = data.map(row => {
                //                 const obj = {};
                //                 header.forEach((key, index) => {
                //                     obj[key] = row[index];
                //                 });
                //                 return obj;
                //             });
        
                //             // Display the processed array
                //             sortReports(dataArray, userSnapshot.val().releases);
                //         })
                //         .catch(error => {
                //             console.error('Error fetching and processing CSV file:', error);
                //         });
                //     })
                // })
        //     }
        // })

        // axios.get(`https://bne-backend.vercel.app/reports/${uid}`)
        // .then(result => {
        //     sortReports(result.data)
        // })
        // .catch(err => console.log(err))

    }, [])

    async function downloadJSONFile(monthYear) {
        const fileRef = sRef(storage, `reports/${monthYear}.json`);
      
        try {
          const url = await getDownloadURL(fileRef);
          const response = await fetch(url);
          const jsonData = await response.json();
          
          const userReports = await jsonData.filter(item => item["User ID"] === uid)
          return userReports
        //   console.log(userReports)
        //   sortReports(userReports);
        } catch (error) {
          console.error('Error downloading JSON file:', error);
          return null; // Return null if download fails
        }
    }

    const getUniqueYears = (array) => {
        const uniqueYears = new Set();
        array.forEach(entry => {
            const [year, month] = entry.monthYear.split('-'); // Assuming monthYear is formatted as 'month/year'
            uniqueYears.add(year);
        });
        return Array.from(uniqueYears).sort(); // Sort the years in ascending order
    }
  
    const filterByYear = (array, selectedYear) => {
      const newValues = array.filter(entry => {
          const [year, month] = entry.monthYear.split('-'); // Assuming monthYear is formatted as 'month/year'
          return year === selectedYear;
      });
      setYear(selectedYear)
      setFilteredReports(newValues)
    }

    useEffect(() => {
        const years = getUniqueYears(reports)
        setYearFilter(years)
        filterByYear(reports, years[years.length - 1])
    }, [reports])


    const sortReports = (reportsData) => {

        const reportsArray = []

        for (const record in reportsData) {
            reportsArray.push(reportsData[record])
        }

        const date = new Date()
        const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`
        const totalPrices = reportsArray

        if (totalPrices.length > 0) {

            const totalsByMonthYear = {};

            totalPrices.forEach(report => {
                const statementPeriod = report["Statement Period"]; // Assuming statement_period is in the format "YYYY-MM"
                const [year, month] = statementPeriod.split('-').map(Number); // Extract year and month as numbers
            
                let reportMonth;
                reportMonth = `${month}/${year}`;
            
                const monthYear = reportMonth;
            
                if (!totalsByMonthYear[monthYear]) {
                    // If the monthYear does not exist in totalsByMonthYear, create a new object with an array of the current report object and the total revenue
                    totalsByMonthYear[monthYear] = {
                        reports: [report],
                        totalRevenue: report["Earnings"] * 1,
                        netRevenue: report["Remain"] * 1,
                        totalStreams: report["Quantity"] * 1
                    };
                } else {
                    // If the monthYear already exists, push the current report object into the existing array and update the total revenue
                    totalsByMonthYear[monthYear].reports.push(report);
                    totalsByMonthYear[monthYear].totalRevenue += report["Earnings"] * 1;
                    totalsByMonthYear[monthYear].netRevenue += report["Remain"] * 1;
                    totalsByMonthYear[monthYear].totalStreams += report["Quantity"] * 1;
                }
            });
            
            

            const monthlyTotalArray = Object.entries(totalsByMonthYear).map(([monthYear, {totalRevenue, netRevenue, totalStreams, reports}]) => ({
                monthYear,
                totalRevenue,
                netRevenue,
                totalStreams,
                reports
            }));


            setReports(prevReports => [...prevReports, ...monthlyTotalArray])
            
            console.log(monthlyTotalArray)

        }
    }

    ///// ORDER MONTHS /////    
    const sortArrayByMonthYear = (array) => {
        return array.sort((a, b) => {
            const [aMonth, aYear] = a.monthYear.split('/').map(Number);
            const [bMonth, bYear] = b.monthYear.split('/').map(Number);
    
            // Compare years first
            if (aYear !== bYear) {
                return aYear - bYear;
            }
    
            // If years are the same, compare months
            return aMonth - bMonth;
        });
    }

    // const sortedReports = sortArrayByMonthYear(filteredReports)

    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredReports.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredReports.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Function to convert JSON data to CSV format
    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]); // Get headers from object keys
        const csvRows = [
            headers.join(','), // Join headers with commas
            ...data.map(row => headers.map(header => row[header]).join(',')) // Map data to CSV format
        ];
        return csvRows.join('\n'); // Join all rows with new line character
    };

    // Function to trigger CSV download
    const downloadCSV = (data, monthYear) => {
        const csvData = convertToCSV(data); // Convert data to CSV format
        const blob = new Blob([csvData], { type: 'text/csv' }); // Create a Blob from the CSV data
        const url = window.URL.createObjectURL(blob); // Create a download link from the Blob
        
        // Create a temporary link element and trigger a download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${monthYear}.csv`; // Set file name
        a.click();
        window.URL.revokeObjectURL(url); // Clean up URL
    };

    const removeCurrencyAndRenameProperties = async (monthYear) => {
        const data = await downloadJSONFile(monthYear)
        const result = await data.map(item => {
            // Destructure to remove "currency" and "Remain After Recoup" properties
            const { currency, 'Remain After Recoup': recoupRemain, 'Earnings': earnings, 'User ID': userID, Remain: netRevenue, ...rest } = item;
            
            // Rename properties if needed
            const updatedItem = {
                ...rest,
                'Net Revenue': netRevenue, // Change "Remain" to "Net Revenue"
            };
    
            return updatedItem; // Return object with removed and renamed properties
        });

        downloadCSV(result, monthYear)
        
    };


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Reports</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-card-heading mb-3">
                    <h2 className="dash-card-title">Reports</h2>
                    <div className="date-range-selector">
                        <div className="dash-form-group">
                        <label>Year</label>
                        <div className="dropdown dash-form-dropdown">
                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {year}
                            </button>
                            <ul className="dropdown-menu">
                                {
                                    yearFilter.map((item, i) => (
                                        <li 
                                            key={i}
                                            onClick={() => filterByYear(reports, item)}  
                                        >
                                            {item}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
                    <div className="table-responsive mt-3">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>Month</td>
                                    <td>Net Revenue</td>
                                    <td>Total Streams</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentItems.map((report, i) => (
                                        <tr key={i}>
                                            <td>{report.monthYear}</td>
                                            <td>${report.netRevenue.toFixed(2)}</td>
                                            <td>{report.streams}</td>
                                            <td>
                                                <div className="table-item d-flex">
                                                    <i 
                                                        className="fi fi-rr-eye table-action-btn a-blue me-2"
                                                        onClick={() => navigate(report.monthYear)}
                                                    ></i>
                                                    <i 
                                                        className="fi fi-rr-download table-action-btn a-green"
                                                        onClick={() => removeCurrencyAndRenameProperties(report.monthYear)}
                                                    ></i>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="table-pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                        </button>
                        ))}
                    </div> */}
                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                </div>
            </div>
        </div>

    </div>
  )
}

export default Reports